// Текст
.text {
  &-uppercase {
    text-transform: uppercase;
  }

  &-left {
    text-align: left;
  }

  &-center {
    text-align: center;
  }

  &-right {
    text-align: right;
  }

  &-justify {
    text-align: justify;
  }

  @include getMedia {
    @for $i from 5 through 25 {
      &-#{calc($i * 2)} {
        font-size: var(--text-#{calc($i * 2)});
      }
    }
  }
}

// Шрифты
@each $key,
$value in $font-family {
  .font-#{$key} {
    font-family: var(--font-#{$key});
  }
}

// Жирность
@for $i from 1 to 10 {
  .bold-#{$i} {
    font-weight: #{calc($i * 100)};
  }
}

// Межстрочный интервал
@for $i from 1 to 10 {
  .lh-#{$i} {
    line-height: #{calc(($i / 10) + 1)};
  }
}