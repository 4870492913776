// Главный блок
.main {
  min-height: 940px;

  &__bg {
    &::after {
      content         : "";
      position        : absolute;
      inset           : 0;
      background-image: linear-gradient(to top, var(--color-black) 20%, var(--color-none) 80%);
    }
  }

  &__subtitle {
    z-index: 1;

    &::before {
      content         : "";
      position        : absolute;
      left            : 0;
      bottom          : 8px;
      right           : 0;
      width           : 100%;
      height          : 12px;
      background-color: var(--color-blue-40);
      z-index         : -1;
    }
  }

  @media(min-width: $sm) {
    &__bg {
      &::after {
        display: none;
      }
    }
  }

  @media(min-width: 2000px) {
    & {
      min-height: 1300px;
    }
  }

  @media(max-width: $lg) {
    & {
      min-height: 700px;
    }

    &__subtitle {
      z-index: 1;

      &::before {
        bottom: 4px;
        height: 6px;
      }
    }
  }

  @media(max-width: $sm) {
    & {
      min-height     : 630px;
      justify-content: flex-end;
    }

    &__image {
      object-position: 80%;
    }
  }
}

// О нас
.about {
  &__pack {
    max-width: 1210px;
  }

  &__content {
    max-width: 650px;
  }

  &__blur {
    left: -210px;
  }

  @media(min-width: $lg) {
    &__content {
      padding-block: 110px;
    }
  }

  @media(min-width: 2000px) {
    &__bg {
      max-width: 1920px;
    }
  }

  @media(max-width: $lg) {
    &__pack {
      max-width: 700px;
    }

    &__content {
      max-width: 510px;
    }
  }

  @media(max-width: $md) {
    &__pack {
      max-width: 550px;
    }

    &__content {
      max-width: 400px;
    }
  }

  @media(max-width: $sm) {
    &__bg {
      position     : static;
      margin-inline: -15px;
    }

    &__pack,
    &__content {
      max-width: 100%;
    }

    &__content {
      margin-top: -70px;
    }
  }
}

// Услуги
.services {
  &__pack {
    &::before {
      padding-top: 65%;
    }
  }

  @media(min-width: 2000px) {
    & {
      max-width: 1920px;
    }
  }
}

// Преимущества
.advantages {
  &__item {
    max-width: 350px;
  }

  @media(min-width: $lg) {
    &__items {
      gap: 56px;
    }
  }

  @media(min-width: 2000px) {
    & {
      padding-inline: 40px;
    }
  }

  @media(max-width: $lg) {
    &__item {
      max-width: 300px;
    }
  }

  @media(max-width: $md) {
    &__item {
      max-width: 100%;
    }
  }
}

// Подход
.approach {
  &__pack {
    max-width: 1330px;
  }

  &__content {
    max-width: 700px;
  }

  &__blur {
    right: -210px;
  }

  @media(min-width: $lg) {
    &__content {
      padding: 60px;
    }
  }

  @media(min-width: 2000px) {
    &__bg {
      max-width: 1920px;
    }
  }

  @media(max-width: $lg) {
    &__pack {
      max-width: 700px;
    }

    &__content {
      max-width: 510px;
    }
  }

  @media(max-width: $md) {
    &__pack {
      max-width: 550px;
    }

    &__content {
      max-width: 500px;
    }
  }

  @media(max-width: $sm) {
    &__bg {
      position     : static;
      margin-inline: -15px;
    }

    &__pack,
    &__content {
      max-width: 100%;
    }

    &__content {
      margin-top: -70px;
    }
  }
}

// Мероприятия
.events {
  &__pack {
    filter: grayscale(100%);
  }

  @media(hover) {
    &__pack:hover {
      filter: grayscale(0);
    }
  }

  @media(max-width: $md) {
    &-slide.swiper-slide-active &__pack {
      filter: grayscale(0);
    }
  }
}

// Рассчет
.calculation {
  &__blur {
    left: 30%;
  }
}

// Обратная связь
.feedback {
  &__pack {
    max-width: 875px;
  }

  &__content {
    max-width: 725px;
  }

  &__blur {
    left: -210px;
  }

  @media(min-width: $lg) {
    &__content {
      padding: 60px 64px;
    }
  }

  @media(min-width: 2000px) {
    &__bg {
      max-width: 1920px;
    }
  }

  @media(max-width: $lg) {
    &__pack {
      max-width: 700px;
    }

    &__content {
      max-width: 540px;
    }
  }

  @media(max-width: $md) {
    &__pack {
      max-width: 500px;
    }

    &__content {
      max-width: 400px;
    }
  }

  @media(max-width: $sm) {
    &__bg {
      position     : static;
      margin-inline: -15px;
    }

    &__pack,
    &__content {
      max-width: 100%;
    }

    &__content {
      margin-top: -100px;
    }
  }
}

// Квиз
.quiz {
  &-slider {
    &[data-quiz="stop"] .swiper-button-next {
      pointer-events: none;
      opacity       : 0.5;
    }

    &[data-quiz-end] &__btn--next {
      display: none;
    }

    &[data-quiz-end] &__submit {
      display: flex;
    }

    &__btn {
      top  : 0;
      left : 0;
      right: 0;

      &--prev {
        min-width: 60px;
        width    : 60px;

        &.swiper-button-disabled {
          color           : var(--color-white);
          background-color: var(--color-white-40);
        }
      }
    }
  }

  &__content {
    max-width: 440px;
  }
}

.footer-bottom {
  color: rgb(255, 255, 255);
  padding-top: 0;
  padding-bottom: 0;
}

.footer-bottom-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  border-top: 1px solid rgba(217, 217, 217, 0.2);
}

.footer-bottom-link {
  text-decoration: underline;
  text-underline-offset: 4px;
}
