// Тело документа
body {
  color           : var(--color-white);
  background-color: var(--color-black);

  &[data-theme="light"] {
    & [data-theme="item"] {
      --color-primary: #EFEFEF;
      --color-black  : #FFFFFF;
      --color-white  : #151515;

      & [data-movement] {
        --color-primary: #1D1D1D;
        --color-white  : #FFFFFF;
        --color-black  : #151515;
      }

      & .input {
        border-color: #151515;

        &::placeholder {
          color: var(--color-black-60);
        }
      }

      & .blur {
        display: none;
      }
    }
  }
}

// Выделение
::selection {
  color           : var(--color-black);
  background-color: var(--color-white);
}