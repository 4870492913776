// Получение трех цветов по rgb
@function rgbValuesFromHex($hex) {
  @return red($hex),
  green($hex),
  blue($hex);
}

// Создание переменных цвета с разной прозрачностью
@mixin getColors($color, $name) {
  @if ($name=="none") {
    --color-#{$name}: #{$color};
  }

  @else {
    $rgb                  : rgbValuesFromHex($color);
    --color-#{$name}      : #{$color};
    --color-#{$name}-10   : rgba(#{$rgb}, 0.1);
    --color-#{$name}-20   : rgba(#{$rgb}, 0.2);
    --color-#{$name}-30   : rgba(#{$rgb}, 0.3);
    --color-#{$name}-40   : rgba(#{$rgb}, 0.4);
    --color-#{$name}-50   : rgba(#{$rgb}, 0.5);
    --color-#{$name}-60   : rgba(#{$rgb}, 0.6);
    --color-#{$name}-70   : rgba(#{$rgb}, 0.7);
    --color-#{$name}-80   : rgba(#{$rgb}, 0.8);
    --color-#{$name}-90   : rgba(#{$rgb}, 0.9);
    --color-#{$name}-light: #{lighten($color, 10%)};
    --color-#{$name}-dark : #{darken($color, 10%)};
  }
}

// Создание медиа-запросов
@mixin getMedia {

  @content;

  @media(min-width: $md) {
    &-md {
      @content;
    }
  }

  @media(min-width: $lg) {
    &-lg {
      @content;
    }
  }
}

// Создание внешних и внутренних отступов
@mixin getSpacing($value) {
  @include getMedia {
    @for $i from 0 through 10 {
      &-#{$i} {
        #{$value}: #{calc($i * $spacing)};
      }
    }
  }
}

// Создание переменных для кнопки
@mixin getBtn($color) {
  &--#{$color} {
    --btn-color : var(--color-#{$color});
    --btn-fade  : var(--color-#{$color}-30);
    --btn-dark  : var(--color-#{$color}-dark);
    --btn-light : var(--color-#{$color}-light);
    --btn-focus : var(--color-#{$color}-40);
  }
}