// Кнопка светящаяся
.btn--glow {
  z-index: 1;

  &::before {
    content      : "";
    filter       : blur(4px);
    position     : absolute;
    inset        : -4px;
    border-radius: inherit;
    transition   : opacity 0.3s ease-in-out;
    animation    : glowing 20s linear infinite;
    opacity      : 0;
    z-index      : -1;

    background: {
      image: linear-gradient(120deg, #ff304f, #581b98, #482ff7, #fc5185, #17b978, #ea5455, #2b1de8, #dd00f3);
      size : 400%
    }
  }

  &::after {
    content         : "";
    position        : absolute;
    inset           : 0;
    border-radius   : inherit;
    background-color: var(--btn-color);
    transition      : 0.3s ease-in-out;
    z-index         : -1;
  }

  @media(hover) {
    &:hover {

      &::before {
        opacity: 1;
      }
    }
  }
}

@keyframes glowing {

  0%,
  100% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }
}