// Air-datepicker
.air-datepicker-global-container {
  z-index: 1100;
}

// Fancybox
.fancybox {
  &__spinner {
    width : auto;
    height: auto;
  }

  &__container * {

    &,
    &::before,
    &::after {
      box-sizing: border-box;
    }
  }

  &__content {
    padding   : 0;
    background: none;
    max-width : 100%;

    & :focus:not(.carousel__button.is-close) {
      outline: none;
    }

    &>.carousel__button.is-close {
      top             : 8px;
      right           : 8px;
      color           : var(--color-black);
      background-color: var(--color-white);
      box-shadow      : 0 4px 8px var(--color-black-10);
    }
  }

  @media(max-width: $md) {
    &__content>.carousel__button.is-close {
      position: fixed;
    }
  }
}

// Slider-swiper
.swiper {
  &--overwrap {
    margin : -15px;
    padding: 15px;
  }

  &-pagination {
    display        : flex;
    align-items    : center;
    justify-content: center;
    position       : static;

    &-bullet {
      width           : 8px;
      height          : 8px;
      margin-inline   : 10px !important;
      opacity         : 0.1;
      background-color: currentColor;
      transition      : background-color 0.2s ease;

      &-active {
        opacity: 1;
      }
    }
  }

  &-button {

    &-prev,
    &-next {
      &::after {
        display: none;
      }
    }

    &-prev:not(.default),
    &-next:not(.default) {
      width        : 52px;
      height       : 52px;
      position     : absolute;
      border-radius: 50%;
    }

    @media(min-width: $md) {
      &-prev:not(.default) {
        left: -40px;
      }

      &-next:not(.default) {
        right: -40px;
      }
    }

    @media(min-width: $lg) {
      &-prev:not(.default) {
        left: -77px;
      }

      &-next:not(.default) {
        right: -77px;
      }
    }
  }
}