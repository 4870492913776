// Рамка
.border {
  border: 1px solid currentColor;

  &-top {
    border-top: 1px solid currentColor;
  }

  &-left {
    border-left: 1px solid currentColor;
  }

  &-right {
    border-right: 1px solid currentColor;
  }

  &-bottom {
    border-bottom: 1px solid currentColor;
  }

  &-none {
    border: none;
  }
}