// Отображение содержимого
.overflow {
  &-visible {
    overflow: visible;
  }

  &-hidden {
    overflow: hidden;
  }

  &-scroll {
    overflow: scroll;
  }

  &-auto {
    overflow: auto;
  }
}