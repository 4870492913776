// Контейнер
.container {
  --padding-block : 40px;
  --padding-inline: 100vw - 30px;
  padding         : var(--padding-block) calc(50% - ((var(--padding-inline)) / 2));

  @media(min-width: $sm) {
    &--sm {
      --padding-inline: var(--sm);
    }
  }

  @media(min-width: $md) {
    & {
      --padding-block : 50px;
      --padding-inline: var(--md);
    }
  }

  @media(min-width: $lg) {
    & {
      --padding-block : 90px;
      --padding-inline: var(--lg);
    }
  }
}