// Курсор
.pointer {
  cursor: pointer;

  &-all {
    pointer-events: all;
  }

  &-none {
    pointer-events: none;
  }
}