// Дисплей
.d {
  @include getMedia {
    &-block {
      display: block;
    }

    &-inline {
      display: inline;
    }

    &-flex {
      display: flex;
    }

    &-grid {
      display: grid;
    }

    &-none {
      display: none;
    }
  }
}