// Прелоадер
.preloader {
  position       : fixed;
  inset          : 0;
  z-index        : 325;
  display        : flex;
  align-items    : center;
  justify-content: center;
  background     : var(--color-black);
  transition     : .5s ease;
}

body.load-complete .preloader {
  opacity       : 0;
  visibility    : hidden;
  pointer-events: none;
}

// Обертка
.smooth-wrapper {
  padding: inherit;
}

// Логотип
.logo {
  width: 220px;

  @media(max-width: $md) {
    & {
      width: 150px;
    }
  }
}

// Шапка документа
.header {
  top             : 0;
  left            : 0;
  right           : 0;
  background-color: var(--color-black-40);
  backdrop-filter : blur(4px);
  transform       : translateY(var(--top));

  &__btn {
    border-color: var(--color-white-20);
  }
}

// Мобильное меню
.mobile {
  inset: 0;

  &__content {
    max-width : 425px;
    box-shadow: 0 0 0 100vw var(--color-black-50);
  }

  &__link {
    display: inline-block;
  }

  &:not([data-mobile="open"]) {
    opacity   : 0;
    visibility: hidden;
  }

  &:not([data-mobile="open"]) &__content {
    transform : translateX(100%);
    opacity   : 0;
    visibility: hidden;
  }

  &[data-mobile="open"] {
    opacity   : 1;
    visibility: visible;
  }

  &[data-mobile="open"] &__content {
    transform : translateX(0);
    opacity   : 1;
    visibility: visible;
  }

  @media(hover) {
    &__link:hover {
      opacity  : 1;
      transform: scale(1.1) translateX(-7px);
    }
  }

  @media(min-width: $lg) {
    &__nav {
      padding-left: 100px;
    }
  }
}

// Размытие
.blur {
  position        : absolute;
  top             : -350px;
  width           : 700px;
  height          : 330px;
  filter          : blur(200px);
  background-color: currentColor;
  animation       : blur 5s linear both infinite;
}

@keyframes blur {

  0%,
  100% {
    opacity: 0.3;
  }

  50% {
    opacity: 0.7;
  }
}

// Подвал
.footer {
  &__btn {
    min-width: 32px;
    width    : 32px;
    height   : 32px;
  }

  @media(max-width: $md) {
    & {
      flex-direction: column;
    }
  }
}

// Модальное окно
.dialog {
  &__blur {
    left: -292px;
  }

  @media(min-width: $lg) {

    &--politics,
    &--feedback {
      padding: 60px 64px;
    }

    &--submit {
      width        : 580px;
      padding-block: 100px;
    }
  }
}