// Движение
.movement {
  &::after {
    content       : "";
    position      : absolute;
    top           : 0;
    left          : 0;
    width         : 0;
    height        : 0;
    transform     : translate(-50%, -50%) translate(var(--x), var(--y));
    transition    : width 0.3s ease, height 0.3s ease;
    pointer-events: none;
    z-index       : 1;
  }

  &[data-movement="light"] {
    &::after {
      background-image: radial-gradient(circle closest-side, var(--color-white), transparent);
      opacity         : 0.3;
    }
  }

  &[data-movement="blend"] {
    position: relative;

    &::after {
      mix-blend-mode  : difference;
      border-radius   : 50%;
      background-color: var(--color-white);
    }
  }

  @media(hover) {
    &[data-movement="light"]:hover::after {
      width : 250px;
      height: 250px;
    }

    &[data-movement="blend"]:hover::after {
      width : 70px;
      height: 70px;
    }
  }
}